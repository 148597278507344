import { PRIVACY_CLASSNAMES, PrivacyLevel } from "@/lib/analytics/shared";
import Tooltip from "@components/library/Tooltip";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { SelectContent, Select as SelectRoot, SelectTrigger, SelectValue } from "@components/ui/select";

import { ExclamationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { Select as AntdSelect } from "antd";
import { useField, useFormikContext } from "formik";
import { ConditionalWrapper, ObjectLiteral, classNames, cn } from "lib/utils";
import React, {
  FormEventHandler,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
  useCallback,
  useId,
  useRef,
} from "react";
import { None } from "@components/llama/Tables/Logs/LogCells";
import { InfoTooltip } from "./InfoTooltip";

export const Error = ({ name, error, className }: { name?: string; error?: React.ReactNode; className?: string }) => {
  if (!error) return null;
  return (
    <div
      className={classNames(
        "duration-400 text-12-14 text-oldred-600 transition-all",
        error ? "max-h-200 animate-fade-in-down pb-1 pt-4" : "max-h-[0px] animate-fade-out-up",
        className,
      )}
      id={`${name}-error`}
    >
      {error}
      {/* NSBP needed so the error box doesn't instantly collapse */}
      &nbsp;
    </div>
  );
};

export const InputLabel = ({
  name,
  label,
  corner,
  className,
  infoTooltipContent,
}: {
  name: string;
  label?: string | React.ReactNode;
  corner?: React.ReactNode;
  className?: string;
  infoTooltipContent?: string;
}) => {
  if (!label && !corner) return null;
  return (
    <div className={classNames("flex justify-between", className)}>
      <div className="flex items-center gap-4">
        <label htmlFor={name} className={`block select-none text-12-12 font-medium text-text-base-2`}>
          {label}
        </label>
        {infoTooltipContent && <InfoTooltip content={infoTooltipContent} />}
      </div>
      <span className="text-12-12 text-oldgray-700">{corner}</span>
    </div>
  );
};
const Description = ({ description }: { description?: React.ReactNode }) => {
  if (!description) return null;
  return <div className="mb-2 mt-4 text-11-16 text-text-base-2">{description}</div>;
};

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string | React.ReactNode;
  corner?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: string;
  error?: React.ReactNode;
  boxSize?: 32 | 24;
  handleSubmit?: FormEventHandler<HTMLFormElement>;
  addOnLeft?: React.ReactNode;
  suppressAddOnLeftPadding?: boolean;
  addOnRight?: React.ReactNode;
  suppressAddOnRightPadding?: boolean;
  className?: string;
  containerClasses?: string;
  errorClasses?: string;
  privacyLevel?: PrivacyLevel;
}

const TextInputWithRef = (
  {
    name,
    label,
    corner,
    description,
    placeholder,
    type,
    error,
    disabled,
    boxSize = 32,
    handleSubmit,
    addOnLeft,
    suppressAddOnLeftPadding,
    addOnRight,
    suppressAddOnRightPadding,
    className,
    containerClasses,
    errorClasses,
    privacyLevel,
    ...props
  }: TextInputProps,
  ref: React.LegacyRef<HTMLInputElement>,
) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (handleSubmit && e.key === "Enter" && e.metaKey) {
      handleSubmit(e as any); // TODO: Fix this `any`
    }
  };

  const roundedClass = boxSize === 24 ? "rounded-ms" : "rounded";
  const boxClasses = boxSize === 24 ? "h-24 text-12-12" : "h-32 text-13-16";
  const inputClasses = boxSize === 24 ? "py-0 text-12-12 px-8" : "py-[7px] text-13-16 px-12";
  return (
    <div className={containerClasses}>
      <InputLabel name={name} label={label} corner={corner} />
      <Description description={description} />
      <div
        className={cn(
          label ? "mt-12" : description ? "mt-4" : "mt-0",
          "flex items-stretch justify-between overflow-hidden border outline-none",
          "text-oldgray-800 placeholder-oldgray-500",
          "ring-0 transition-all focus-within:outline-none focus-within:ring focus-within:ring-opacity-40",
          boxClasses,
          roundedClass,
          error
            ? "border-oldred-600 placeholder-oldred-400 focus-within:ring-oldred-200 "
            : disabled
              ? "border-oldgray-300"
              : " border-oldgray-400 focus-within:border-oldblue-600 focus-within:ring-oldblue-200", // TODO: Decide on fix for clipped ring outline.
          disabled && "bg-oldgray-100 text-oldgray-500",
          privacyLevel && PRIVACY_CLASSNAMES[privacyLevel],
          className,
        )}
      >
        {/* TODO: there's a bug where if you tab into this input, the addOnLeft disappears. */}
        {addOnLeft && (
          <span
            className={classNames(
              "inline-flex shrink-0 items-center rounded-r-none border border-y-0 border-l-0 border-oldgray-300 bg-oldgray-200 text-12-14 text-oldgray-600",
              roundedClass,
              !suppressAddOnLeftPadding && "pl-10 pr-12",
            )}
          >
            {addOnLeft}
          </span>
        )}
        <input
          name={name}
          type={type || "text"}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          autoCapitalize={props.autoCapitalize || "off"}
          autoComplete={props.autoComplete || "off"}
          autoCorrect={props.autoCorrect || "off"}
          {...props}
          className={cn(
            "w-full min-w-0 grow border-none bg-transparent outline-0 ",
            disabled && "cursor-not-allowed",
            inputClasses,
            addOnLeft ? "pl-4" : "",
            className,
          )}
          aria-invalid={!!error}
          aria-describedby={`${name}-error`}
          // Added this ref, but not sure if it's needed.
          ref={ref}
        />
        {addOnRight && (
          <span
            className={classNames(
              "inline-flex shrink-0 items-center rounded-l-none border border-y-0 border-r-0 border-oldgray-300 bg-oldgray-200 text-oldgray-600 sm:text-12-14",
              roundedClass,
              !suppressAddOnRightPadding && "pl-12 pr-10",
            )}
          >
            {addOnRight}
          </span>
        )}
      </div>

      <Error name={name} error={error} className={errorClasses} />
    </div>
  );
};

export const TextInput = forwardRef(TextInputWithRef);

type FormikTextInputProps = TextInputProps & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  additionalError?: string;
};

export const FormikTextInput = forwardRef<HTMLInputElement, FormikTextInputProps>(
  ({ onChange, additionalError, ...props }, ref) => {
    // field: An object containing onChange, onBlur, name, and value of the field (see FieldInputProps)
    const [field, meta] = useField(props.name);
    const error = meta.touched && meta.error;

    return (
      <TextInput
        ref={ref}
        error={error || additionalError}
        {...props}
        {...field}
        onBlur={(e) => {
          field.onBlur(e);
          props.onBlur && props.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
      />
    );
  },
);
FormikTextInput.displayName = "FormikTextInput";

interface FormikUnstyledProps {
  label: string;
  id: string;
  name: string;
  className: string;
  placeholder?: string;
  corner?: string;
  remainingProps: React.InputHTMLAttributes<HTMLInputElement>; // Props passed through to `input` element
}

export const FormikUnstyled = ({
  label,
  id,
  name,
  className,
  placeholder,
  corner,
  ...remainingProps
}: FormikUnstyledProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField(name);
  const error = meta.touched && !!meta.error;
  return (
    <>
      {Boolean(corner) && (
        <div className={`flex justify-between`}>
          <span className="text-sm leading-5 text-oldgray-600">{corner}</span>
        </div>
      )}

      <div className="group flex items-baseline">
        <ConditionalWrapper condition={error} wrapper={(children) => <Tooltip content={error}>{children}</Tooltip>}>
          <input
            ref={inputRef}
            placeholder={placeholder}
            {...field}
            {...remainingProps}
            className={classNames(
              "transition-default block w-full rounded px-8 py-6",
              error
                ? "text-oldred-900 focus:ring-red border-oldred-300 bg-oldred-100 placeholder-oldred-500 focus:border-oldred-300"
                : "focus:ring-blue group-hover:ring-blue placeholder-oldgray-500 group-hover:border-oldblue-300 focus:border-oldblue-300 focus:outline-none",
              className,
            )}
            aria-invalid={!!error}
          />
        </ConditionalWrapper>
        <Tooltip side="right" content={`${label || name} ${(label || name) && " - "}click to edit`}>
          <PencilIcon
            className="ml-8 h-20 w-20 text-oldgray-300 group-hover:text-oldgray-700"
            onClick={() => {
              inputRef && inputRef.current?.focus();
            }}
          />
        </Tooltip>
      </div>
      {error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
          <ExclamationCircleIcon className="h-20 w-20 text-oldred-600" />
        </div>
      )}
    </>
  );
};

export const FormikTagInput = (props: {
  label: string;
  id: string;
  name: string;
  className?: string;
  corner?: string;
  props?: ObjectLiteral;
}) => {
  const [field, meta, helpers] = useField(props.name);
  const error = meta.touched && meta.error;
  const formikProps = useFormikContext();
  return (
    <>
      <InputLabel name={props.name} label={props.label} corner={props.corner} />
      <div className={classNames("relative w-full rounded shadow-sm", props.label ? "mt-12" : "mt-4")}>
        <AntdSelect
          mode="tags"
          value={field.value}
          onChange={(value, option) => {
            formikProps.setFieldValue(field.name, value);
          }}
          placeholder="swag"
          onBlur={(value) => {
            formikProps.setFieldTouched(props.name);
          }}
          {...props}
        />

        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
            <ExclamationCircleIcon className="h-20 w-20 text-oldred-600" />
          </div>
        )}
      </div>
      <Error name={props.name} error={error} />
      {/* <Debug vars={{ field, meta, props }} /> */}
    </>
  );
};

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  id?: string;
  label?: string;
  infoTooltipContent?: string;
  type?: string;
  error?: React.ReactNode;
  corner?: string | React.ReactNode;
  description?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit?: FormEventHandler<HTMLTextAreaElement>;
  value?: string;
  className?: string;
}

export const Textarea = forwardRef(
  (
    {
      name,
      id,
      label,
      description,
      infoTooltipContent,
      type,
      corner,
      onSubmit,
      onChange,
      error,
      value,
      className,
      ...rest
    }: TextareaProps,
    ref: React.LegacyRef<HTMLTextAreaElement>,
  ) => {
    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
      if (onSubmit && e.key === "Enter" && e.metaKey) {
        onSubmit(e);
        e.stopPropagation();
      }
    };

    return (
      <>
        <InputLabel name={name} label={label} infoTooltipContent={infoTooltipContent} corner={corner} />
        <Description description={description} />
        <textarea
          {...rest}
          value={value}
          onKeyDown={handleKeyDown}
          className={classNames(
            // my-1 to stop the border being clipped. Not sure if we even want this border on focus but ok.
            "transition-default form-input my-1 block w-full appearance-none rounded-ms border border-stroke-base-4 px-12 py-8 text-12-16 ",
            "placeholder-text-base-4 ",
            "scrollbar-thin !scrollbar-track-transparent !scrollbar-thumb-oldgray-500",
            (label || description) && "mt-8",
            className,
          )}
          onChange={onChange}
          aria-describedby={`${id}-error`}
          ref={ref}
        />
        <Error name={name} error={error} />
      </>
    );
  },
);
Textarea.displayName = "Textarea";

export const FormikTextarea = ({ name, id, ...rest }: TextareaProps) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  const inputId = id || name;

  const onBlur = useCallback(
    (e: React.FocusEvent) => {
      if ("onBlur" in field) {
        // Manually setting touched state
        // as I can't tell why onBlur isn't doing so
        // (For Editor commit modal - PromptCommitModal component)
        field.onBlur(e);
        helpers.setTouched(true);
      }
    },
    [field.onBlur, helpers],
  );

  return (
    <Textarea
      {...field}
      onBlur={onBlur}
      {...rest}
      id={inputId}
      error={error}
      onChange={(e) => {
        helpers.setValue(e.target.value);
      }}
    />
  );
};

interface FormikRangeProps {
  name: string;
  label: string;
  id?: string;
  className?: string;
  multiple?: boolean;
  onChange: (value: string) => void;
}

export const FormikRange = ({
  label,
  onChange,
  multiple = false,
  ...props
}: React.PropsWithChildren<FormikRangeProps>) => {
  const [field, meta, helpers] = useField(props.name);

  const error = meta.touched && meta.error;
  const id = props.id || props.name;

  return (
    <div className={props.className}>
      <label htmlFor={id} className="block text-sm font-bold leading-5 text-oldgray-700">
        {label}
      </label>

      <select
        {...field}
        onChange={(e) => {
          onChange ? onChange(e.target.value) : helpers.setValue(e.target.value);
        }}
        multiple={multiple}
        className="focus:ring-blue form-select mt-4 block w-full border-oldgray-400 py-8 pl-12 pr-40 text-base leading-6 focus:border-oldblue-300 focus:outline-none sm:text-sm sm:leading-5"
      >
        {props.children}
      </select>
      <Error name={props.name} error={error} />
    </div>
  );
};

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string | React.ReactNode;
  corner?: React.ReactNode;
  description?: React.ReactNode;
  infoTooltipContent?: string;
  placeholder?: string;
  error?: React.ReactNode;
  customTrigger?: React.ReactNode;
  className?: string;
  hideIcon?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  size?: 20 | 24 | 32; // If the user was hoping to use 'size' to control the number in the select, extend this component!
  contentProps?: React.ComponentPropsWithoutRef<typeof SelectContent>;
}

export const SelectInput = ({
  size,
  name,
  label,
  infoTooltipContent,
  corner,
  description,
  disabled,
  placeholder,
  error,
  className,
  customTrigger,
  children,
  hideIcon = false,
  value,
  setValue,
  contentProps,
}: SelectProps) => {
  return (
    <div
      className={className}
      onKeyDown={(e) => {
        // Prevent the up down keys from also moving the active row in the table etc.
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.stopPropagation();
        }
      }}
    >
      {/* Dislike that radix ui doesn't expose a typical
      onChange event handler. Makes this not behave like a regular html select annoyingly and need to pass in a specific prop.
      Now I hate it =/ */}
      <SelectRoot
        name={name}
        value={value}
        onValueChange={(value) => {
          setValue && setValue(value);
        }}
        disabled={disabled}
      >
        <InputLabel
          name={name}
          label={label}
          infoTooltipContent={infoTooltipContent}
          corner={corner}
          className="mb-8"
        />
        <Description description={description} />
        <SelectTrigger asChild={!!customTrigger} error={!!error} size={size} className={className} hideIcon={hideIcon}>
          {customTrigger ?? <SelectValue className={className} placeholder={placeholder} />}
        </SelectTrigger>
        <SelectContent sideOffset={4} {...contentProps}>
          {children}
        </SelectContent>
      </SelectRoot>
      <Error name={name} error={error} />
    </div>
  );
};

type FormikSelectProps = SelectProps & { name: string };

export const FormikSelect = (props: FormikSelectProps) => {
  // field: An object containing onChange, onBlur, name, and value of the field (see FieldInputProps)
  const [field, meta, helpers] = useField(props.name);
  const error = meta.touched && meta.error;

  return <SelectInput error={error} {...field} {...props} setValue={helpers.setValue} />;
};

interface FormikCheckboxProps {
  name: string;
  label: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
}

export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const [field, meta, helpers] = useField(props.name);
  const error = meta.touched && meta.error;

  return (
    <div className="flex items-center gap-12">
      <input
        id={props.name}
        type="checkbox"
        {...field}
        {...props}
        onChange={(e) => {
          helpers.setValue(e.target.checked);
        }}
        className={classNames(
          "transition-default form-checkbox h-16 w-16 rounded border-oldgray-400 text-oldblue-600 shadow-sm focus:border-oldblue-300 focus:ring focus:ring-oldblue-200 ",
          error && "text-oldred-900 border-oldred-300 shadow-sm focus:border-oldred-300 focus:ring-oldred-200",
          props.disabled && "cursor-not-allowed bg-oldgray-100 text-oldgray-500",
        )}
      />
      <label htmlFor={props.name} className="">
        {props.label}
      </label>
    </div>
  );
};

interface FormikRadioGroupProps {
  name: string;
  label?: React.ReactNode;
  corner?: React.ReactNode;
  options: { value: string; label: string; disabled?: boolean }[];
  disabledTooltipMessage?: string;
  disableLabels?: boolean;
  orientation: "horizontal" | "vertical";
  className?: string;
}

export const FormikRadioGroup = ({
  name,
  label,
  corner,
  options,
  disabledTooltipMessage,
  disableLabels,
  orientation,
  className,
}: FormikRadioGroupProps) => {
  const [field, meta, helpers] = useField(name);
  const fieldId = useId();

  const hasError = meta.touched && meta.error;

  return (
    <div>
      {label && <InputLabel name={name} label={label} corner={corner} />}
      <RadioGroup
        defaultValue={options[0].value}
        className={classNames("gap-[12px]", orientation === "vertical" ? "grid-flow-row" : "grid-flow-col")}
        onValueChange={(value) => {
          helpers.setValue(value);
        }}
        orientation="horizontal"
        value={field.value}
      >
        {options.map(({ value, label, disabled }) => (
          <ConditionalWrapper
            key={value}
            condition={disabled !== undefined && disabled}
            wrapper={(children) => <Tooltip content={disabledTooltipMessage}>{children}</Tooltip>}
          >
            <div key={value} className={classNames("flex w-full grow items-center", className)}>
              <RadioGroupItem value={value} id={`${fieldId}-${value}`} disabled={disabled} />
              {!disableLabels && (
                <label htmlFor={`${fieldId}-${value}`} className="text-13-16 text-text-base-1">
                  {label}
                </label>
              )}
            </div>
          </ConditionalWrapper>
        ))}
      </RadioGroup>
      {hasError && <div className="mt-2 text-red-500">{meta.error}</div>}
    </div>
  );
};
